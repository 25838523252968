<template>
	<div class="fl-blog fl-item2 bd-activity bd-shadow-on-hover" style="display: block;">
		<div class="item row bd-desktop" style="height: 75px;">
			<div class="infor-item col-md-1" style="position: relative;">
				<div class="media" style="position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);">
					<router-link :to="'/nft/'+offer.mint_address" style="width: 7rem;">
            <img :src="offer.nft_picture">
          </router-link>
				</div>
			</div>


			<div class="infor-item col-md-2" style="position: relative;">
				<span class="bd-activity-text bd-text-to-long-container">
					<router-link :to="'/nft/'+offer.mint_address" class="bd-collectionpage-activity-title bd-text-to-long">{{ offer.nft_name }}</router-link>
					<router-link :to="'/collection-page/'+offer.collection_symbol" class="bd-collectionpage-activity-title bd-text-to-long">{{ offer.collection_name }}</router-link>
				</span>
			</div>
			<div class="col-md-1" style="position: relative;">
				<span class="bd-activity-text bd-activity-price"><b>◎ {{ offer.price | reduce_number(2,'down')}}</b></span>
			</div>
			<div class="col-md-2" style="position: relative;">
				<span class="bd-activity-text bd-activity-text bd-activity-fromto">
          <template v-if="context === 'receive'">
            Offerer:
            <router-link  v-if="offer.buyer !== null" :to="'/user/'+offer.buyer.wallet"><b>{{ offer.buyer.wallet | reduce_text(3,3)}}</b></router-link>
            <router-link  v-else-if="offer.buyer_address !== null" :to="'/user/'+offer.buyer_address"><b>{{ offer.buyer_address | reduce_text(3,3)}}</b></router-link>
          </template>

          <template v-else>
            Seller:
            <router-link  v-if="offer.seller !== null"  :to="'/user/'+offer.seller.wallet"><b>{{ offer.seller.wallet | reduce_text(3,3)}}</b></router-link>
            <router-link  v-if="offer.seller === null && offer.seller_address !== null"  :to="'/user/'+offer.seller_address"><b>{{ offer.seller_address | reduce_text(3,3)}}</b></router-link>
          </template>
				</span>
			</div>
			<div class="col-md-2" style="position: relative;">
				<span class="bd-activity-text"><b>{{ offer.date | moment("MMM Do YY, h:mm a") }}</b></span>
			</div>

      <div class="col-md-2" style="position: relative;" v-if="context === 'made'">
        <span class="bd-nftcard-makeoffer bd-nftcard-cancel badge bd-tag" @click="$root.$children[0].$data.modal.cancel_make_offer = {show_modal:true,offer:offer}">Cancel</span>
			</div>

      <div class="col-md-2" style="position: relative;" v-else>
        <span class="bd-nftcard-makeoffer bd-nftcard-cancel badge bd-tag" @click="accept_offer(offer)">Accept</span>
			</div>

		</div>
	</div>
</template>

<script>

import {accept_offer_program } from '@/libs/rustProgram';
import {getSolanaObject} from "../libs/Wallet";

export default {
  name: "ListOffer",
  props:{
    offer: {},
    context: {
      type: String,
      default: 'made',
    },
  },
  methods:{
    async accept_offer(offer) {

      var $root = this.$root;

      if ($root.connected == 0)
        $root.$emit('show_modal', ['connect_wallet', true]);

      else {

        $root.loading(true);

        var response = true;

        response = await accept_offer_program(getSolanaObject(), offer, offer.nft_escrow_account).catch(
            (error) => {
              response = "Error while confirming transaction, please refresh and try again !";
            }
        );

        if (response !== true) {

          $root.loading(false);

          $root.$dtoast.pop({
            preset: "error",
            heading: `Error`,
            content: `Something went wrong, please refresh and try again`,
          });


        } else {

          setTimeout(function () {

            $root.$dtoast.pop({
              preset: "success",
              heading: `Success`,
              content: `Congratulations, this offer is now accepted !`,
            });

            $root.$emit('change_accept_make_offer');

            $root.loading(false);

          }, 2500);

        }
      }
    },
  },
}
</script>