<template>
	<div class="fl-blog fl-item2 bd-activity bd-shadow-on-hover" style="display: block;">
		<div class="item row bd-desktop" style="height: 75px;">
			<div class="col-md-2" style="position: relative;">
				<span v-show="activity.type == 24" class="bd-activity-type">
					<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span>
					<span class="bd-activity-name">
						<span class="color-primary" v-if="activity.buyer !== null && $root.connected == 1 && $root.wallet_address == activity.buyer.wallet">Bought</span>
						<span class="color-primary" v-else-if="activity.seller !== null && $root.connected == 1 && $root.wallet_address == activity.seller.wallet">Sold</span>
						<span class="color-primary" v-else>Sale</span>
					</span>
				</span>
				<span v-show="activity.type == 25" class="bd-activity-type">
					<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span>
					<span class="bd-activity-name"><span class="color-primary">Sale</span></span>
				</span>
				<span v-show="activity.type == 23" class="bd-activity-type">
					<span class="fa fa-hands-helping bd-activity-icon color-primary"></span>
					<span class="bd-activity-name color-primary">Offer</span>
				</span>
				<span v-show="activity.type == 29" class="bd-activity-type">
					<span class="fa fa-hands-helping bd-activity-icon color-primary"></span>
					<span class="bd-activity-name color-primary">Cancel offer</span>
				</span>
				<span v-show="activity.type == 21" class="bd-activity-type">
					<span class="fa fa-tag bd-activity-icon color-primary"></span>
					<span class="bd-activity-name color-primary">Listing</span>
				</span>
				<span v-show="activity.type == 22" class="bd-activity-type">
					<span class="fa fa-tag bd-activity-icon color-primary"></span>
					<span class="bd-activity-name color-primary">Cancel listing</span>
				</span>
			</div>
			<div class="infor-item col-md-1" style="position: relative;">
				<div class="media" style="position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);">
					<router-link :to="'/nft/'+activity.mint_address" style="width: 7rem;">
            <img :src="activity.picture">
          </router-link>
				</div>
			</div>
			
			
			<div class="infor-item col-md-2" style="position: relative;">
				<span class="bd-activity-text bd-text-to-long-container">
					<router-link :to="'/nft/'+activity.mint_address" class="bd-collectionpage-activity-title bd-text-to-long">{{ activity.name }}</router-link>
					<router-link v-show="all_activity === true" :to="'/collection-page/'+activity.symbol" class="bd-collectionpage-activity-title bd-text-to-long">{{ activity.collection_name }}</router-link>
				</span>
			</div>
			<div class="col-md-1" style="position: relative;">
				<span class="bd-activity-text bd-activity-price"><b>◎ {{ activity.price | reduce_number(2,'down')}}</b></span>
			</div>
			<div class="col-md-2" style="position: relative;">
				<span class="bd-activity-text bd-activity-ranking" style="margin-right: 2px;">
					{{ activity.ranking }}
					<span class="fa fa-gem color-primary" style="margin-right: 2px;" v-if="activity.diamonds >= 1"></span>
					<span class="fa fa-gem color-primary" style="margin-right: 2px;" v-if="activity.diamonds >= 2"></span>
				</span>
				
			</div>
			<div class="col-md-2" style="position: relative;">
				<span class="bd-activity-text bd-activity-text bd-activity-fromto">
					Buyer:
          <router-link  v-if="activity.buyer !== null" :to="'/user/'+activity.buyer.wallet"><b>{{ activity.buyer.wallet | reduce_text(3,3)}}</b></router-link>
          <router-link  v-else-if="activity.buyer_address !== null" :to="'/user/'+activity.buyer_address"><b>{{ activity.buyer_address | reduce_text(3,3)}}</b></router-link><br/>
					Seller:
          <router-link  v-if="activity.seller !== null"  :to="'/user/'+activity.seller.wallet"><b>{{ activity.seller.wallet | reduce_text(3,3)}}</b></router-link>
          <router-link  v-if="activity.seller === null && activity.seller_address !== null"  :to="'/user/'+activity.seller_address"><b>{{ activity.seller_address | reduce_text(3,3)}}</b></router-link>
				</span>
			</div>
			<div class="col-md-2" style="position: relative;">
				<span class="bd-activity-text"><b>{{ activity.date | moment("MMM Do YY, h:mm a") }}</b></span>
			</div>
			
		</div>
		<div class="item row bd-mobile bd-activity-mobile">
			<div class="col-4">
				<router-link :to="'/nft/'+activity.mint_address"><img :src="activity.picture"></router-link>
			</div>
			<div class="col-8 bd-flex-column">
				<span>
					<span v-show="activity.type == 24" class="bd-activity-type">
						<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span>
						<span class="bd-activity-name"><span class="color-primary">Sale for ◎ {{ activity.price }}</span></span>
					</span>
					<span v-show="activity.type == 25" class="bd-activity-type">
						<span class="fa fa-cart-arrow-down bd-activity-icon color-primary"></span>
						<span class="bd-activity-name"><span class="color-primary">Sale for ◎ {{ activity.price }}</span></span>
					</span>
					<span v-show="activity.type == 23" class="bd-activity-type">
						<span class="fa fa-hands-helping bd-activity-icon color-primary"></span>
						<span class="bd-activity-name color-primary">Offer for ◎ {{ activity.price }}</span>
					</span>
					<span v-show="activity.type == 21" class="bd-activity-type">
						<span class="fa fa-tag bd-activity-icon color-primary"></span>
						<span class="bd-activity-name color-primary">Listing for ◎ {{ activity.price }}</span>
					</span>
					<span v-show="activity.type == 22" class="bd-activity-type">
						<span class="fa fa-tag bd-activity-icon color-primary"></span>
						<span class="bd-activity-name color-primary">Cancel listing</span>
					</span>
				</span>
				<span>
					<span class="bd-activity-text" style="margin-right: 2px;"><span class="bd-mobile">Rank : </span>{{ activity.ranking }}</span>
					<span class="fa fa-gem color-primary bd-activity-text" style="margin-right: 2px;" v-if="activity.diamonds >= 1"></span>
					<span class="fa fa-gem color-primary bd-activity-text" style="margin-right: 2px;" v-if="activity.diamonds >= 2"></span>
				</span>
				<span class="bd-activity-text">Buyer: <router-link :to="'/user/'+activity.buyer_address">{{ activity.buyer_address | reduce_text(3,3)}}</router-link></span>
				<span class="bd-activity-text">Seller: <router-link  :to="'/user/'+activity.seller_address">{{ activity.seller_address | reduce_text(3,3)}}</router-link></span>
				<span class="bd-activity-text">{{ activity.date | moment("MMM Do YY, h:mm a") }}</span>
				
			</div>
			
			
			
			
			
		</div>
	</div>
</template>

<script>

export default {
  name: 'CollectionActivity',
  components: {
	
  },
  props: {
    activity: {},
    all_activity : {
      type : Boolean,
      default: false,
    }
  },
  created: function() {
  
	
  },
}
</script>

