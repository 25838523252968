<template>
	<div class="tf-create-item tf-section">
		<div class="themesflat-container bd-container-padding" style="margin-top: 100px;" v-show="my_account_tab == 'edit_profile'">
			<div class="row bd-account-edit-container">
				<!-- avatar -->
				<div class="col-md-3 center">
					<div class="bd-account-avatar" style="margin-right: auto; margin-left: auto;">
						<img id="profileimg" v-if="user.picture == null || user.picture == 'null' || user.picture == undefined || user.picture == ''" src="/assets/images/avatar/default_avatar.png" alt="Image">
						<img id="profileimg" v-else :src="route_bo + '/storage/' + user.picture" alt="Image">
					</div>
					<span class="bd-button-icon-container" id="upload-profile">
						<i class="bd-button-icon fas fa-pen"></i>
						<input id="tf-upload-img" type="file" name="profile" @change="profil_pic_preview()">
					</span>
					<h3 class="bd-account-h3-avatar">Avatar</h3>
				</div>
				<!-- name & email -->
				<div class="col-md-9">
					<div class="bd-account-edit bd-shadow">
						<div class="row">
							<div class="col-md-12">
								<h3>Details</h3>
								<br/>
								<span class="bd-account-baseline">Add some information about yourself !</span>
							</div>
						</div>
						<div class="row">
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-8">
										<div class="input-group">
											<div class="bd-account-input-group-prepend">
												<span class="fa fa-signature"></span>
											</div>
											<input type="text" placeholder="Your name here" v-model="user.name">
										</div>
									</div>
								</div>
								<br/>
								<div class="row">
									<div class="col-md-8">
										<div class="input-group">
											<div class="bd-account-input-group-prepend">
												<span class="fa fa-at"></span>
											</div>
											<input type="text" placeholder="Your email address" v-model="user.email">
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<textarea placeholder="Short bio" style="height: 122px" v-model="user.bio"></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- social networks -->
			<div class="row">
				<div class="col-md-12">
					<div class="bd-account-edit bd-account-edit-social-networks bd-shadow">
						<h3 class="bd-account-h3-social-networks">Social networks</h3>
						<div class="row">
							<div class="col-md-6">
								<div class="input-group">
									<div class="bd-account-input-group-prepend">
										<span class="fab fa-twitter"></span>
									</div>
									<input type="text" v-model="user.twitter" placeholder="Your twitter handle">
								</div>
							</div>
							<div class="col-md-6">
								<div class="input-group">
									<div class="bd-account-input-group-prepend">
										<span class="fab fa-instagram"></span>
									</div>
									<input type="text" placeholder="Your instagram handle">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<!-- save -->
			<div class="row">
				<div class="col-md-6" >
					<div class="btn btn-secondary bd-account-back-button" @click="my_account_tab = 'home'">
						<span class="fas fa-long-arrow-alt-left"></span> Back
					</div>
				</div>
				<div class="col-md-6" style="text-align: right">
					<div class="btn btn-primary bd-account-save-button" @click="update_profile()">
						<span class="fa fa-check"></span> Save changes
					</div>
				</div>
			</div>
		</div>
		<div class="themesflat-container bd-account-home-tab" v-show="my_account_tab == 'home'">
			<!-- header account desktop -->
			<div class="row bd-account-header bd-container-padding-large bd-desktop">
				<!-- avatar -->
				<div class="col-md-2">
					<div class="bd-account-avatar">
						<img v-if="user.picture == null || user.picture == 'null' || user.picture == undefined || user.picture == ''" src="/assets/images/avatar/default_avatar.png" alt="Image">
						<img v-else :src="route_bo + '/storage/' + user.picture" alt="Image">
					</div>
				</div>
				<!-- name, bio & badges -->
				<div class="col-md-8">
					<div class="bd-account-header-flex">
						<div>
							<span class="bd-account-name" v-if="user.name !== null && user.name != undefined && user.name != ''">{{ user.name }}</span>
							<span class="bd-account-name" v-else>Unknown user #{{ user.id }}</span>
							<br/>
							<span class="bd-account-bio">{{ user.bio }}</span>
						</div>
						<div>
							<span v-if="user.og == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.og == 1 ? 'active' : '')" title="OG BlueDiamonds">
								<span :class="'bd-account-badge fa fa-certificate '+(user.og == 1 ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/1st-place-medal_1f947.png"></span>
							</span>
							<span v-if="user.fox != 1 && user.fox != 1 && ( user.fox == 1 || (connected_user) )" :class="'bd-account-badge-container '+(user.fox == 1 ? 'active' : '')"  title="<200 SOL volume traded">
								<span :class="'bd-account-badge fa fa-certificate '+(user.fox == 1 ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/fox_1f98a.png"></span>
							</span>
							<span v-if="user.lion != 1 && ( user.lion == 1 || (connected_user) )" :class="'bd-account-badge-container '+(user.lion == 1 ? 'active' : '')"  title="200 SOL volume traded">
								<span :class="'bd-account-badge fa fa-certificate '+(user.lion ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/lion_1f981.png"></span>
							</span>
							<span v-if="user.whale == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.whale == 1 ? 'active' : '')" title="1000 SOL volume traded">
								<span :class="'bd-account-badge fa fa-certificate '+(user.whale ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/spouting-whale_1f433.png"></span>
							</span>
							<span v-if="user.fast == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.fast == 1 ? 'active' : '')" title="Bought an NFT listed less than 5 minutes before">
								<span :class="'bd-account-badge fa fa-certificate '+(user.fast == 1 ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/high-voltage_26a1.png"></span>
							</span>
							<span v-if="user.sniper == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.sniper == 1 ? 'active' : '')" title="Sniper : made a x10 on a single NFT">
								<span :class="'bd-account-badge fa fa-certificate '+(user.sniper == 1 ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/direct-hit_1f3af.png"></span>
							</span>							
							<span v-if="user.brand_ambassador == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.brand_ambassador == 1 ? 'active' : '')" title="Ambassador BlueDiamonds">
								<span :class="'bd-account-badge fa fa-certificate '+(user.brand_ambassador ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/speaker-high-volume_1f50a.png"></span>
							</span>
							<span v-if="user.collection_owner == 1 || (connected_user)" :class="'bd-account-badge-container '+(user.collection_owner == 1 ? 'active' : '')" title="Collection owner">
								<span :class="'bd-account-badge fa fa-certificate '+(user.collection_owner ? 'active' : '')"></span>
								<span class="bd-account-badge-icon"><img src="/assets/icon/briefcase_1f4bc.png"></span>
							</span>
						</div>
					</div>
				</div>
				<!-- miscellaneous -->
				<div class="col-md-2 bd-account-misc">
					<div class="bd-account-header-flex">
						<div>
							
							<a :href="'https://twitter.com/'+user.twitter" v-if="user.twitter !== null && user.twitter != '' && user.twitter != undefined">
								<span class="fab fa-twitter bd-account-badge-misc bd-account-badge-twitter" v-if="user.twitter !== null && user.twitter != '' && user.twitter != undefined"></span>
							</a>
							<span class="bd-button-filter-collection-display"  @click="my_account_tab = 'edit_profile'" v-if="connected_user">
								<i class="bd-button-icon fas fa-pen"></i>
							</span>
						</div>
						<div v-if="connected_user" class="bd-account-balance-container">
							<span class="bd-account-balance">{{ user.balance | reduce_number(2,'down') }} SOL</span>
							<i class="bd-account-badge-wallet fa fa-wallet color-primary"></i>
						</div>
					</div>
				</div>
			</div>
			
			<!-- header account mobile -->
			<div class="bd-account-header bd-mobile">
				<!-- avatar -->
				<div class="col-md-2 center">
					<div class="bd-account-avatar-mobile">
						<img v-if="user.picture == null || user.picture == 'null' || user.picture == undefined || user.picture == ''" src="/assets/images/avatar/default_avatar.png" alt="Image">
						<img v-else :src="route_bo + '/storage/' + user.picture" alt="Image">
					</div>
				</div>
				<!-- name, bio & badges -->
				<div class="col-md-2 center">
					<div>
						<span class="bd-account-name" v-if="user.name !== null && user.name != undefined && user.name != ''">{{ user.name }}</span>
						<span class="bd-account-name" v-else>Unknown user #{{ user.id }}</span>
						<br/>
						<span class="bd-account-bio">{{ user.bio }}</span>
					</div>
					
				</div>
			</div>
			
			<!-- Tabs desktop -->
			<div class="bd-container-padding-large bd-desktop">
				<div class="bd-account-tabs">
					<div class="bd-account-tabs-left bd-shadow">
						<span :class="'badge bd-tag ' + (active_tab === 'my_nft' ? 'active' : '')" @click="switch_tab('my_nft')" v-html="connected_user ? 'My NFTs' : 'NFTs'"></span>
						<span v-if="connected_user" :class="'badge bd-tag ' + (active_tab === 'my_offers' ? 'active' : '')" @click="switch_tab('my_offers')">Offers</span>
						<span v-if="connected_user" :class="'badge bd-tag ' + (active_tab === 'favorites' ? 'active' : '')" @click="switch_tab('favorites')">Favorites</span>
					</div>
					<div class="bd-account-tabs-right" v-if="connected_user">
						<span :class="'badge bd-tag ' + (active_tab === 'activities' ? 'active' : '')" @click="switch_tab('activities')">Activities</span>
						<span :class="'badge bd-tag ' + (active_tab === 'notifications' ? 'active' : '')" @click="switch_tab('notifications')">Notifications</span>
					</div>
				</div>
			</div>
			
			<!-- Tabs mobile -->
			<div class="bd-mobile center">
				<span :class="'badge bd-tag ' + (active_tab === 'my_nft' ? 'active' : '')" @click="switch_tab('my_nft')" v-html="connected_user ? 'My NFTs' : 'NFTs'"></span>
			</div>
			
			<!-- My NFT -->
			<section class="tf-explore tf-section bd-collectionpage-main" v-show="active_tab == 'my_nft'">
				<div class="themesflat-container">
					<div class="row">
						<div class="col-xl-3 col-lg-3 col-md-12">
							<div id="side-bar" class="side-bar style-3">
								<div class="widget widget-category mgbt-24">
									<div class="title-wg-category boder-bt">
										<h4 class="title-widget style-2">Status</h4>
									</div>
									<div class="content-wg-category">
										<form action="#">
											<div>
												<label> All
												<input type="radio" :checked="filter_my_nft === 'all'" @change="filter_my_nft = 'all';">
												<span class="btn-checkbox"></span>
												</label>
											</div>
											<div>
												<label> Listed
												<input type="radio" :checked="filter_my_nft === 'list'" @change="filter_my_nft = 'list';">
												<span class="btn-checkbox"></span>
												</label>
											</div>
											<div>
												<label> Unlisted
												<input type="radio" :checked="filter_my_nft === 'unlist'" @change="filter_my_nft = 'unlist';">
												<span class="btn-checkbox"></span>
												</label>
											</div>
										</form>
									</div>
								</div>
                <div class="widget mgbt-24">
                  <div class="title-wg-category boder-bt">
                    <h4 class="title-widget style-2">Collection</h4>
                  </div>
                  <FilterCollection ref="CollectionFilter" :filters="filters.nft" :collection_filters="my_nft" :loaded="user_page_loaded"></FilterCollection>
                </div>
							</div>
							
							
						</div>

						<div class="col-xl-9 col-lg-9 col-md-12 bd-collectionpage-nft-zone bd-container-padding" style="padding-top: 0px;">
							

							<div v-for="(collection, collection_key) in nfts_to_show" :key="collection_key" v-show="user_page_loaded && !reload_list_nft_activate">
								<h2 style="padding: 0px 0px 20px 0px;">{{ collection.name }}</h2>
								<div class="box-epxlore" id="nfts-box" v-if="(!reload_activate || scroll_activate)">
									<NftCard  class="bd-account-nft-card four-per-line" :nft="nft" :nft_per_line="'4'" v-show="nft.owner === user_wallet_address || nft.owner_when_listed === user_wallet_address" v-for="(nft, key) in collection.nfts" :key="key" :context="(connected_user ? 'my_account_my_nft' : '')"></NftCard>
									<div v-if="modulo[collection.id] >= 1" class="sc-card-product bd-empty-nftcard four-per-line"></div>
									<div v-if="modulo[collection.id] >= 2" class="sc-card-product bd-empty-nftcard four-per-line"></div>
									<div v-if="modulo[collection.id] >= 3" class="sc-card-product bd-empty-nftcard four-per-line"></div>
									<div v-if="modulo[collection.id] >= 4" class="sc-card-product bd-empty-nftcard four-per-line"></div>
									
								</div>
							</div>

							<div v-show="!user_page_loaded || reload_list_nft_activate">
                <span class="badge badge-success bd-noresult-buy" style="padding: 0px 0px 20px 0px;width: 120px;margin-bottom: 20px;">&nbsp;</span>
								<div class="box-epxlore" id="nfts-box">
									<NoResultsNft3 :nft_per_line="'four'"></NoResultsNft3>
                  <NoResultsNft3 :nft_per_line="'four'"></NoResultsNft3>
                  <NoResultsNft3 :nft_per_line="'four'"></NoResultsNft3>
                  <NoResultsNft3 :nft_per_line="'four'"></NoResultsNft3>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section>
			<!--
			<div v-show="active_tab == 'my_nft'">
				<div class="bd-account-filter-tab-my-nft">
					<label>
					<input type="radio" :checked="filter_my_nft === 'all'" @change="filter_my_nft = 'all';" >
					<span class="btn-checkbox"></span>
					All
					</label>
					<label>
					<input type="radio" :checked="filter_my_nft === 'list'" @change="filter_my_nft = 'list';">
					<span class="btn-checkbox"></span>
					Listed
					</label>
					<label>
					<input type="radio" :checked="filter_my_nft === 'unlist'" @change="filter_my_nft = 'unlist';">
					<span class="btn-checkbox"></span>
					Unlisted
					</label>
				</div>
				<div class="row" v-for="(collection, collection_key) in nfts_to_show" :key="collection_key">
					<div class="col-xl-12 col-lg-8 col-md-12 col-12 bd-account-list-nft-card">
            <div class="box-epxlore" id="nfts-box">
              <NftCard class="bd-account-nft-card" v-for="(nft, key) in collection.nfts" :key="key" :nft="nft" :nft_per_line="'four'" :context="(connected_user ? 'my_account_my_nft' : '')"></NftCard>
              <div v-if="modulo[collection.id] >= 1" class="sc-card-product bd-empty-nftcard four-per-line"></div>
              <div v-if="modulo[collection.id] >= 2" class="sc-card-product bd-empty-nftcard four-per-line"></div>
              <div v-if="modulo[collection.id] >= 3" class="sc-card-product bd-empty-nftcard four-per-line"></div>
              <div v-if="modulo[collection.id] >= 4" class="sc-card-product bd-empty-nftcard four-per-line"></div>
            </div>
					</div>
				</div>
				<div class="row" v-show="Object.keys(nfts_to_show).length === 0">
					<div class="col-xl-12 col-lg-8 col-md-12 col-12 bd-account-list-nft-card">
						<h1>No NFT found</h1>
					</div>
				</div>
			</div>
			-->

      <div class="row bd-account-offers-tab bd-container-padding" v-if="connected_user" v-show="active_tab == 'my_offers'">
				<div class="col-12 bd-account-notifications-side">
          <template v-if="user.my_account_offers.made !== undefined">
            <div class="row">
              <div class="col-12" style="display: table">
                <h1 class="bd-account-offers-tab-title center">Offers made</h1>
              </div>
            </div>
			<div class="bd-no-result"  v-if="user.my_account_offers.made.length === 0">
				<h2 style="text-align:center"> No result found </h2>
			</div>
			<div class="row bd-collectionpage-activity-titles" v-if="user.my_account_offers.made.length > 0">
				<div class="col-md-3">
					Nft
				</div>
				<div class="col-md-1">
					Price
				</div>
				<div class="col-md-2">
					Seller
				</div>
				<div class="col-md-2">
					Date
				</div>
				<div class="col-md-4">
					Actions
				</div>
			</div>
            <div>
              <ListOffer context="made" :offer="offer_made" v-for="(offer_made,offer_made_key) in user.my_account_offers.made" :key="offer_made_key"></ListOffer>
            </div>
          </template>
          <template v-if="user.my_account_offers.received !== undefined">
            <div class="row">
              <div class="col-12" style="display: table">
                <h1 class="bd-account-offers-tab-title center" style="margin-top: 40px;">Offers received</h1>
              </div>
            </div>
			<div class="bd-no-result"  v-if="user.my_account_offers.received.length === 0">
				<h2 style="text-align:center"> No result found </h2>
			</div>
			<div class="row bd-collectionpage-activity-titles" v-if="user.my_account_offers.received.length > 0">
				<div class="col-md-3">
					Nft
				</div>
				<div class="col-md-1">
					Price
				</div>
				<div class="col-md-2">
					Buyer
				</div>
				<div class="col-md-2">
					Date
				</div>
				<div class="col-md-4">
					Actions
				</div>
			</div>
            <div>
              <ListOffer context="receive" :offer="offer_receive" v-for="(offer_receive,offer_receive_key) in user.my_account_offers.received" :key="offer_receive_key"></ListOffer>
            </div>
          </template>
        </div>
			</div>

			<!-- favorites -->
			<div class="row bd-account-notifications-tab bd-container-padding" v-show="active_tab == 'favorites'">
				<div class="col-12 bd-account-notifications-side">
					<template v-if="user.my_account_favorites_nfts.length !== 0 || user.my_account_favorites_collections.length !== 0">
						<div class="row">
							<div class="col-12" style="display: table">
								<h1 class="bd-account-notifications-tab-title center">Collections</h1>
							</div>
						</div>
						<div class="row bd-account-notifications-list-notifications">
							<div class="col-12 bd-account-notifications-notification" v-if="user.my_account_favorites_collections.length === 0">
								<div class="row bd-account-notifications-notification-row last">
									<div class="col-md-12">
										<div class="bd-no-result" >
											<h2 style="text-align:center"> No result found </h2>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 bd-account-notifications-notification">
								<div class="box-epxlore" id="collection-box">
									<CollectionCard :collection="collection" v-for="(collection, key_collection) in user.my_account_favorites_collections" :key="key_collection"></CollectionCard>
								</div>	
							</div>											
						</div>	
						<div class="row">
							<div class="col-12" style="display: table">
								<h1 class="bd-account-notifications-tab-title center">NFTs</h1>
							</div>
						</div>
						<div class="row bd-account-notifications-list-notifications">
							<div class="col-12 bd-account-notifications-notification" v-if="user.my_account_favorites_nfts.length === 0">
								<div class="row bd-account-notifications-notification-row last">
									<div class="col-md-12">
										<div class="bd-no-result" >
											<h2 style="text-align:center"> No result found </h2>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 bd-account-notifications-notification">
								<div class="box-epxlore" id="nft-box">	
									<NftCard :nft="nft" :nft_per_line="$root.number_element_per_line" v-for="(nft, key_nft) in user.my_account_favorites_nfts" :key="key_nft"></NftCard>
								</div>
							</div>											
						</div>	
					</template>	
					<template v-else>
						<div class="row bd-account-notifications-list-notifications">
							<div class="col-12 bd-account-notifications-notification" >
								<div class="row bd-account-notifications-notification-row last">
									<div class="col-md-12">
										<div class="bd-no-result" >
											<h2 style="text-align:center"> No result found </h2>
										</div>
									</div>
								</div>
							</div>
																	
						</div>	
					</template>
				</div>
			</div>

			<!-- notifications -->
			<div class="row bd-account-notifications-tab bd-container-padding" v-if="connected_user" v-show="active_tab == 'notifications'">
				<div class="col-xl-3 col-lg-3 col-md-12 bd-account-settings-notifications-side">
					<div class="row">
						<div class="col-12">
							<h3 class="bd-account-notifications-tab-title bd-account-notifications-tab-title-settings">Settings</h3>
						</div>
						<div class="col-12 bd-account-notifications-category-title" v-for="(setting_notification,key_setting_notification) in settings_notifications" :key="key_setting_notification">
							<div class="row">
								<div class="col-md-1"></div>
								<div class="col-md-11">
									<h6>{{ setting_notification.name }}</h6>
								</div>
							</div>
							<div class="row" v-for="(name_setting,key_setting) in setting_notification.settings" :key="key_setting">
								<div class="col-md-1 bd-account-notifications-col-toggle">
									<Toggle v-model="user.notifications_settings[key_setting_notification][key_setting]" @change="update_notification_setting" class="bd-account-notifications-toggle"/>
								</div>
								<div class="col-md-10">
									<p class="bd-account-notifications-setting-name">{{ name_setting }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-9 col-lg-9 col-md-12 bd-account-notifications-side">
					<div class="row">
						<div class="col-7" style="display: table">
							<h3 class="bd-account-notifications-tab-title">Your Notifications</h3>
						</div>
						<div class="col-5">
							<div class="seclect-box style-2 box-right" style="width: auto;">
								<div id="collection_filters_notifications" class="dropdown bd-dropdown">
									<a class="btn-selector nolink" style="width: 250px;">Sales</a>
									<ul>
										<li @click="notification_category = 18"><span>Sales</span></li>
										<li @click="notification_category = 19"><span>Offers</span></li>
										<li @click="notification_category = 20"><span>Fav NFTs</span></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="row bd-account-notifications-list-notifications">
						<div class="col-12 bd-account-notifications-notification" v-if="user.my_account_notifications.length === 0">
							<div class="row bd-account-notifications-notification-row last">
								<div class="col-md-12">
									<div class="bd-no-result" >
										<h2 style="text-align:center"> No result found </h2>
									</div>
								</div>
							</div>
						</div>
						<template v-for="(notification, key) in user.my_account_notifications">
							<div class="col-12 bd-account-notifications-notification" v-if="notification.notification_category == notification_category" :key="key" >
								<div :class="'row bd-account-notifications-notification-row ' + (key+1 === user.my_account_notifications.length ? 'last' : '')">
									<!-- collection logo -->
									<div class="col-md-2 center">
										<router-link v-if="notification.collection != null && notification.collection.logo != null" :to="'/collection/'+notification.collection.symbol">
											<img :src="route_bo+'/storage/'+notification.collection.logo" alt="Image" class="bd-account-notifications-collection-logo">
										</router-link>
									</div>
									<!-- Notification content -->
									<div class="col-md-8">
										<div class="bd-account-notifications-notification-text">
											<span class="bd-account-notifications-title" v-html="notification.content"></span>
											<span class="bd-account-notifications-date">{{ notification.date | moment("MMM Do YY, h:mm a") }}</span>
										</div>
									</div>
									<!-- NFT picture -->
									<div class="col-md-2">
										<router-link v-if="notification.nft != null && notification.nft.picture != null" :to="'/nft/'+notification.nft.mint_address">
											<img  :src="notification.nft.picture" class="bd-account-notifications-nft-picture" />
										</router-link>
									</div>
								</div>
							</div>
						</template>
					</div>
					<div class="row">
						<div class="col-12 bd-account-notifications-pagination" v-if="notification_category == 18">
							<pagination v-model="filters.notifications.page"
								:records="count_notifications_sales"
								:per-page=5
								:options="{chunk:5,chunksNavigation :'scroll',edgeNavigation :true,texts :{count:''}}"
								@paginate="reload_list_notifications"/>
						</div>
						<div class="col-12 bd-account-notifications-pagination" v-if="notification_category == 19">
							<pagination v-model="filters.notifications.page"
								:records="count_notifications_offers"
								:per-page=5
								:options="{chunk:5,chunksNavigation :'scroll',edgeNavigation :true,texts :{count:''}}"
								@paginate="reload_list_notifications"/>
						</div>
						<div class="col-12 bd-account-notifications-pagination" v-if="notification_category == 20">
							<pagination v-model="filters.notifications.page"
								:records="count_notifications_fav"
								:per-page=5
								:options="{chunk:5,chunksNavigation :'scroll',edgeNavigation :true,texts :{count:''}}"
								@paginate="reload_list_notifications"/>
						</div>
					</div>
				</div>
			</div>
			
			<!-- activities -->
			
			<div class="bd-container-padding">
				<div class="row" v-if="connected_user" v-show="active_tab == 'activities'">
					<div class="col-xl-3 col-lg-3 col-md-12">
						<div id="side-bar" class="side-bar style-3">
							<div class="widget widget-category mgbt-24">
								<div class="title-wg-category boder-bt">
									<h4 class="title-widget style-2">Type</h4>
								</div>
								<div class="content-wg-category">
									<form action="#">
										<label>
										Listing
										<input type="checkbox" v-model="filters.activities.type.listing" @change="reload_list_activities">
										<span class="btn-checkbox"></span>
										</label><br>
										<label>
										Offers
										<input type="checkbox" v-model="filters.activities.type.offers" @change="reload_list_activities">
										<span class="btn-checkbox"></span>
										</label><br>
										<label>
										Sales
										<input type="checkbox" v-model="filters.activities.type.sales" @change="reload_list_activities">
										<span class="btn-checkbox"></span>
										</label><br>
									</form>
								</div>
								<div class="widget widget-category mgbt-24">
									<div class="title-wg-category boder-bt">
										<h4 class="title-widget style-2">Price</h4>
									</div>
									<div class="content-wg-category">
										<form action="#">
											<div>
												<Slider v-model="filters.activities.price.value" :min="filters.activities.price.min" :max="filters.activities.price.max" :step=0.01 :format="format_filter_price" @update="reload_list_activities"></Slider>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-9 col-lg-9 col-md-12">
						<div class="bd-no-result"  v-if="user.my_account_activities.length === 0">
							<h2 style="text-align:center"> No result found </h2>
						</div>
						<div class="row bd-collectionpage-activity-titles"   v-if="user.my_account_activities.length > 0">
							<div class="col-md-2">
								Activity
							</div>
							<div class="col-md-3">
								Item
							</div>
							<div class="col-md-1">
								Price
							</div>
							<div class="col-md-2">
								Ranking
							</div>
							<div class="col-md-2">
								From/To
							</div>
							<div class="col-md-2">
								Time
							</div>
						</div>
						<div id="activity-box">
							<ListActivity :activity="activity" v-for="(activity, key) in user.my_account_activities" :key="key"></ListActivity>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>

import NftCard from '@/components/NftCard.vue'
import CollectionCard from '@/components/CollectionCard.vue';
import {getWalletAddress} from '@/libs/Wallet.js'
import {getNftOwned,getBalance} from '@/libs/solanaConnection.js'
import $ from 'jquery'
import Toggle from '@vueform/toggle/dist/toggle.vue2.js'
import Pagination from 'vue-pagination-2';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import ListActivity from '@/components/ListActivity.vue';
import FilterCollection from '@/components/Filters/FilterCollection.vue';
import NoResultsNft3 from '@/components/NoResults/NoResultsNft3.vue';

import axios from 'axios';
import ListOffer from "../components/ListOffer";

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}

export default {
	name: 'User',
	components: {
    ListOffer,
		NftCard,
		CollectionCard,
		Toggle,
		Pagination,
		Slider,
		ListActivity,
    FilterCollection,
    NoResultsNft3,
	},
	
	data: function () {

		return {

      user_page_loaded: false,
			my_account_tab: 'home',
			active_tab: 'my_nft',
			followed_tab: 'nft',
			followed_datas: [],
			portfolio: [],
			portfolio_order_by: 'name',
			portfolio_order_by_direction: 'ASC',
			notification_category: 18,
			my_nft: {},
			reload_activate: false,
      reload_list_nft_activate: false,
			filters: {
				notifications : {
					page: 1,
				},
				activities: {
					price: {
						value: [0, 500],
						min: 0,
						max: 500,
					},
					type: {
						listing: true,
						offers: true,
						sales: true,
					},
					number_per_page: 15,
				},
        nft:{
          collection: {},
        }
			},
			filter_my_nft: 'all',
			activity_load: false,
			notifications_settings_save: false,
			user: {
				notifications_settings: {
					sales: {
						nft: false,
					},
					offers: {
						received: false,
						accepted: false,
					},
					fav_nfts: {
						list: false,
						offer: false,
						sell: false,
					},
				},
				my_account_favorites_nfts: {},
				my_account_favorites_collections: {},
				my_account_offers: {},
				my_account_notifications: {},
				my_account_activities: {},
			},
			count_notifications : 1,
			count_notifications_sales: 1,
			count_notifications_offers: 1,
			count_notifications_fav: 1,
			route_api: process.env.VUE_APP_ROOT_API,
			route_bo: process.env.VUE_APP_ROOT_BO,
			twitter_authentication : {
				oauth_token : this.$route.query.oauth_token,
				oauth_verifier : this.$route.query.oauth_verifier,
			},
      refreshKeyConnected: false,
			settings_notifications:{
				sales : {
					name : 'SALES',
					settings : {
						nft : 'Notification for a sale of your NFTS',
					}
				},
				offers : {
					name : 'OFFERS',
					settings : {
						received : 'Notification Offers Received',
						accepted : 'Notification Offers Made Accepted',
					}
				},
				fav_nfts : {
					name : 'FAV NFTs',
					settings : {
						list : 'Notification for listing',
						offer : 'Notification for offers',
						sell : 'Notification for sell',
					}
				},
			}
		}
	},

	mounted: function(){
		window.scrollTo(0, 0)

		var component_vue = this;

		let datas = {
			'user' : component_vue.$root.wallet_address,
		}

		if(component_vue.twitter_authentication.oauth_token != undefined && component_vue.twitter_authentication.oauth_verifier != undefined) {

			datas = {
				'oauth_token': component_vue.twitter_authentication.oauth_token,
				'oauth_token_session': sessionStorage.getItem('oauth_token'),
				'oauth_token_secret': sessionStorage.getItem('oauth_token_secret'),
				'oauth_verifier': component_vue.twitter_authentication.oauth_verifier,
				'user': component_vue.user_wallet_address,
			}
		}
		
		axios.post(process.env.VUE_APP_ROOT_API+'/api/twitter_callback', datas).then(function(response) {

			if(response.data.connected){

				component_vue.twitter_authentication.connected = 1;
				component_vue.twitter_authentication.screen_name = response.data.twitter_screen_name;
			}

			sessionStorage.clear();
			component_vue.$forceUpdate();
		});

    component_vue.load_user_data();
		component_vue.reload_list_nft();
		component_vue.reload_list_favorites();

    if(component_vue.connected_user) {

      component_vue.reload_list_activities(true);
      component_vue.reload_list_notifications();

    }

    component_vue.$root.$on("connection_wallet_accept", async () => {
      component_vue.refreshKeyConnected = !component_vue.refreshKeyConnected;
    });

    component_vue.$root.$on("disconnect", async () => {
      component_vue.switch_tab('my_nft');
      setTimeout(function(){
        component_vue.refreshKeyConnected = !component_vue.refreshKeyConnected;
      },500);
    });

		component_vue.$root.$on('change_list_nft',function(nft_id){

      for (const [key, collection] of Object.entries(component_vue.my_nft)) {

        for (const [nft_key, nft] of Object.entries(collection.nfts)) {

          if(nft.id === nft_id){
              nft.listed = 1;

              return true;
          }
        }

      }

		});

    component_vue.$root.$on('change_cancel_nft',function(nft_id){

      for (const [key, collection] of Object.entries(component_vue.my_nft)) {

        for (const [nft_key, nft] of Object.entries(collection.nfts)) {

          if(nft.id === nft_id){
              nft.listed = null;

              return true;
          }
        }

      }

		});

    component_vue.$root.$on('change_buy_nft',function(nft_id){

      for (const [key, collection] of Object.entries(component_vue.my_nft)) {

        for (const [nft_key, nft] of Object.entries(collection.nfts)) {

          if(nft.id === nft_id){

              nft.owner = component_vue.$root.wallet_address;
              nft.owner_when_listed = null;

              return true;
          }
        }

      }

		});

    component_vue.$root.$on('change_cancel_make_offer',function(){
      component_vue.reload_list_offers();
      component_vue.load_user_data();
    });

    component_vue.$root.$on('change_accept_make_offer',function(){
      component_vue.reload_list_offers();
      component_vue.load_user_data();
    });

		component_vue.scroll();

		if(component_vue.$route.params.tab != '' && component_vue.$route.params.tab != undefined)
			component_vue.switch_tab(component_vue.$route.params.tab);
	},
	
	methods: {

		switch_tab: function(new_tab) {

			this.active_tab = new_tab;

			/*
			if(new_tab == 'notifications' && this.activity_load === false){

			this.reload_list_notifications(false,true);

			this.activity_load = true;
			}
			*/
			if(new_tab == 'notifications'){

				this.reload_list_notifications();
			}

			if(new_tab == 'my_nft'){

				this.reload_list_nft();
			}

			if(new_tab == 'my_offers'){

				this.reload_list_offers();
			}

			if(new_tab == 'activities'){

				this.reload_list_activities();
			}

			if(new_tab == 'favorites'){

				this.reload_list_favorites();
			}

		},

		profil_pic_preview : function(){

			var component_vue = this;

			var file = $('#tf-upload-img').get(0).files[0];

			if(file) {
			
				component_vue.user.picture = null;

				var reader = new FileReader();

				reader.onload = function(){
					$("#profileimg").attr("src", reader.result);
				}

				reader.readAsDataURL(file);
			}

		},

		update_profile : function (){

			var component_vue = this;

			var info_send = new FormData();

			var user_info = JSON.parse(JSON.stringify(component_vue.user));

			for (const [key, value] of Object.entries(user_info)) {
				info_send.append(key, value);
			}

			if(user_info.picture == null && $('#tf-upload-img').get(0).files[0] != undefined) {
			
				info_send.delete('picture');
				info_send.append('picture', $('#tf-upload-img').get(0).files[0]);
			}

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + component_vue.user_wallet_address, info_send).then(async function(user) {

				if(user.data.user !== undefined){

					component_vue.user = user.data.user;

					component_vue.user.my_account_notifications = {};
					component_vue.user.my_account_activities = {};
					component_vue.active_tab = 'my_nft';

					component_vue.user.balance = await getBalance(component_vue.user_wallet_address);

					component_vue.$dtoast.pop({
						preset: "success",
						heading: `Successfully updated`,
					});

          component_vue.reload_list_favorites();
          component_vue.reload_list_offers();

					component_vue.my_account_tab = 'home';
				}
			});

		},

		reload_list_notifications: function(){

			var $this = this;

			var filters = $this.filters['notifications'];

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/reload_notifications/'+$this.user_wallet_address,filters, config_axios).then(function(elements) {

				$this.user.my_account_notifications = elements.data.elements;

				$this.count_notifications = elements.data.count_notifications;

				$this.count_notifications_sales = elements.data.count_notifications_sales;
				$this.count_notifications_offers = elements.data.count_notifications_offers;
				$this.count_notifications_fav = elements.data.count_notifications_fav;

				$this.$forceUpdate();

			});

		},

		reload_list_favorites: function(){


			var $this = this;

			var filters = $this.filters['favorites'];

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/reload_favorites/'+$this.user_wallet_address,filters, config_axios).then(function(elements) {

				$this.user.my_account_favorites_nfts = elements.data.favorites_nfts;
				$this.user.my_account_favorites_collections = elements.data.favorites_collections;

				$this.$forceUpdate();

			});
		},

    reload_list_offers:async function(){
			var $this = this;

      var my_nfts = await getNftOwned($this.user_wallet_address);

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/reload_offers/'+$this.user_wallet_address,my_nfts, config_axios).then(function(elements) {

				$this.user.my_account_offers = elements.data.offers;

				$this.$forceUpdate();

			});
		},

		reload_list_activities: function(initialize = false,scroll = false){

			var $this = this;

			var filters = $this.filters['activities'];

			if(initialize === true)
			filters['initialize'] = true;

			if(scroll === false)
				delete filters.number_per_page;

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/reload_activities/'+$this.user_wallet_address, filters, config_axios).then(function(elements) {

				if(scroll === true)
					$this.user.my_account_activities = $this.user.my_account_activities.concat(elements.data.elements);
				else {
				
					$this.user.my_account_activities = elements.data.elements;
					filters.number_per_page = 15;
				}

				if(initialize === true) {
				
					filters.price = elements.data.filters.price;

					delete filters['initialize'];

				}

				$this.$forceUpdate();

			});

		},

    load_user_data : function(){

      var component_vue = this;

      var wallet_address = component_vue.user_wallet_address;

			axios.get(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + wallet_address, config_axios).then(async function(user) {

				for (const [key, value] of Object.entries(user.data.user)) {

					if(value !== null && value !== 'null')
						component_vue.user[key] = value;
					else
						component_vue.user[key] = undefined;
				}

				component_vue.user.balance = await getBalance(wallet_address);

				component_vue.$forceUpdate();

			});
    },

		async reload_list_nft(){

      var component_vue = this;

      component_vue.reload_list_nft_activate = true;

			var my_nft = await getNftOwned(component_vue.user_wallet_address);
			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/my_nft', {my_nft : my_nft, wallet_address : component_vue.user_wallet_address}).then(function(nfts) {

				component_vue.my_nft = nfts.data;
        setTimeout(function (){

          component_vue.$refs['CollectionFilter'].load_collection();
          component_vue.user_page_loaded = true;
          component_vue.reload_list_nft_activate = false;
        },100);
			});

			component_vue.$forceUpdate();

		},

		update_notification_setting: function() {

			var post_data = this.user.notifications_settings;

			var component_vue = this;

			if(component_vue.notifications_settings_save !== false)
				component_vue.notifications_settings_save.abort();

			component_vue.notifications_settings_save = new AbortController();

			axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/user/' + component_vue.user_wallet_address+'/save_notification_settings',post_data,{signal: component_vue.notifications_settings_save.signal}).then(function(){
				component_vue.notifications_settings_save = false;
			});

		},

		scroll() {

			window.onscroll = () => {

        var element = document.getElementById('activity-box');

        if(element === null)
          return;

				let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= element.offsetHeight

				if (bottomOfWindow && this.reload_activate === false && this.count_activities > this.filters['activities'].number_per_page) {

					this.reload_list_activities(false,true);
					this.filters['activities'].number_per_page += 10;
				}
			}
		},

		format_filter_price: function (value) {
			return `◎ ${value}`
		},

	},
	computed:{

		nfts_to_show: function(){

      var nft_to_return_temporary = {};

      var nft_to_return = {};

      var $this = this;

			if($this.filter_my_nft === 'list'){

        nft_to_return_temporary = JSON.parse(JSON.stringify($this.my_listed_nfts));
			}
			else if($this.filter_my_nft === 'unlist'){

        nft_to_return_temporary = JSON.parse(JSON.stringify($this.my_unlisted_nfts));
			}
			else{

        nft_to_return_temporary = JSON.parse(JSON.stringify($this.my_nft));
			}

      for (const [index, collection] of Object.entries(nft_to_return_temporary)) {

        if($this.filters.nft.collection[collection.id] != undefined && $this.filters.nft.collection[collection.id].select === true)
          nft_to_return[index] = collection;

      }

      if(Object.entries(nft_to_return).length == 0)
        nft_to_return = nft_to_return_temporary;

      return nft_to_return;

		},
		my_listed_nfts: function(){

			var my_listed_nfts = {};

			for (const [key, collection] of Object.entries(this.my_nft)) {

				for(const [nft_key, nft] of Object.entries(collection.nfts)) {

					if(nft.listed === 1 || nft.listed === "1"){
				
						if (my_listed_nfts[key] === undefined) {
						
							my_listed_nfts[key] = JSON.parse(JSON.stringify(collection));
							my_listed_nfts[key].nfts = [];
						}

						my_listed_nfts[key].nfts.push(nft);
					}
				}

			}

			return my_listed_nfts;

		},
		my_unlisted_nfts: function(){

			var my_unlisted_nfts = {};

			for (const [key, collection] of Object.entries(this.my_nft)) {

				for(const [nft_key, nft] of Object.entries(collection.nfts)) {

					if(nft.listed !== 1 && nft.listed !== "1"){
					
						if (my_unlisted_nfts[key] === undefined){
						
							my_unlisted_nfts[key] = JSON.parse(JSON.stringify(collection));
							my_unlisted_nfts[key].nfts = [];
						}

						my_unlisted_nfts[key].nfts.push(nft);
					}
				}

			}

			return my_unlisted_nfts;
		},

    user_wallet_address: function(){
      return this.$route.params.wallet;
    },

    user_tab_selected_url: function(){
			return this.$route.params.tab;
    },

    connected_user : function(){

      this.refreshKeyConnected;

      return this.user_wallet_address === getWalletAddress();
    },



    modulo: function() {

      var $this = this;
      var modulo = [];

      Object.values($this.nfts_to_show).forEach(function(collection, index){

        if(collection == undefined) {

          modulo[collection.id] = 0;
          return;

        }

        if(collection.nfts == undefined) {

          modulo[collection.id] = 0;
          return;

        }

        if(Object.entries(collection.nfts).length == 0) {

          modulo[collection.id] = 0;
          return;

        }

        modulo[collection.id] = parseInt(Object.entries(collection.nfts).length % 4);

      })

      return modulo;

    },
	},
  watch:{
    user_wallet_address: function(){
      this.load_user_data();
      this.reload_list_nft();
    },
    user_tab_selected_url: function(){
			this.switch_tab(this.$route.params.tab);
    },
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style src="@vueform/slider/themes/default.css"></style>

